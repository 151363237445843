<template>
  <div class="content">
    <div class="header flex_direction_column">
      <span>{{ info.name }}</span>
    </div>
    <div class="bottom flex_direction_column">
      <div class="search flex_center">
        <img src="@/assets/image/img/22.png" />
        <div class="box flex_space_between">
          <input v-model="params.key_words" placeholder="输入证件号" />
          <img @click="search" src="@/assets/image/img/21.png" />
        </div>
      </div>
      <div class="result flex_direction_column" v-if="list">
        <div class="result_title">查询结果</div>
        <div class="table flex_direction_column">
          <div class="item flex">
            <div class="left">姓名</div>
            <div class="right">{{ list.name }}</div>
          </div>
          <div class="item flex">
            <div class="left">组别</div>
            <div class="right">{{ list.group || '-' }}</div>
          </div>
        </div>
      </div>
      <Lack
        v-if="!list && !isSearch && (params.key_words || !params.key_words)"
        imgWidth="102px"
        imgHeight="68px"
        text="您还未查询，请输入关键词查询吧~"
        :imgSrc="require('@/assets/image/img/23.png')"
      ></Lack>
      <Lack
        v-if="isSearch && !list && params.key_words && isSearch"
        imgWidth="92px"
        imgHeight="66px"
        text="暂无查询结果"
        :imgSrc="require('@/assets/image/img/26.png')"
      ></Lack>
    </div>
  </div>
</template>

<script>
import { enrollQuery, enrollQueryDo } from '@/service/api/match'
import Lack from '@/components/lack/lack.vue'
export default {
  components: { Lack },
  data() {
    return {
      params: {
        id: 0,
        key_words: ''
      },
      info: {},
      list: '',
      isSearch: false
    }
  },

  mounted() {
    this.params.id = this.$route.query.id
    // 页面数据
    this.enrollQuery(this.$route.query.id)
  },

  methods: {
    // 页面数据
    async enrollQuery(id) {
      const res = await enrollQuery({ id })
      this.info = res.msg
    },
    // 查询结果
    async enrollQueryDo() {
      const res = await enrollQueryDo(this.params)
      this.isSearch = true
      this.list = res.msg
    },
    search() {
      this.enrollQueryDo()
    }
  },
  watch: {
    'params.key_words'() {
      this.isSearch = false
      // this.enrollQueryDo()
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  border-radius: 5px;
  background-color: #fff;
  padding: 31px 52px 26px 52px;
  span {
    &:nth-child(1) {
      line-height: 42px;
      // font-weight: bold;
      font-size: @font_size_26;
    }
    &:nth-child(2) {
      margin-top: 6px;
      line-height: 25px;
      color: @color_twenty;
      font-size: @font_size_18;
    }
  }
}
.bottom {
  margin-top: 20px;
  border-radius: 5px;
  margin-bottom: 17px;
  background-color: #fff;
  padding: 71px 80px 64px 80px;
  .search {
    img {
      width: 75px;
      height: 70px;
    }
    .box {
      width: 785px;
      margin-left: 92px;
      padding: 15px 33px;
      border-radius: 5px;
      border: 1px solid @color_one;
      input {
        width: 550px;
        font-size: @font_size_18;
      }
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .result {
    margin-top: 56px;
    .result_title {
      height: 33px;
      text-align: center;
      line-height: 33px;
      font-weight: bold;
      font-size: @font_size_24;
    }
    .table {
      margin-top: 47px;
      align-items: center;
      justify-content: center;
      .item {
        height: 50px;
        width: 855px;
        line-height: 50px;
        font-size: @font_size_16;
        border: 1px solid #eeeeee;
        border-bottom: none;
        &:last-child {
          border: 1px solid #eeeeee;
        }
        .left {
          width: 300px;
          text-align: center;
          color: @color_fourteen;
          background-color: #fcfcfc;
        }
        .right {
          width: 553px;
          text-align: center;
        }
      }
    }
  }
}
</style>
